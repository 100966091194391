.check-box {
    aspect-ratio: 1/1;
    min-height: 2.5em;
    height: 2.5rem;
    border: 1px solid white;
    box-sizing: border-box;
    // border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__indicator {
      height: .6rem;
      width: .6rem;
      border-radius: 100%;
      background: transparent;
      border-radius: .6rem;
      transition: all .3s ease-out;
      &_active {
        background: #FF8D00;
      }
    }
  
  
}